<template>
  <b-overlay
    :show="fetching"
    rounded="sm"
  >
    <div style="display: flex; justify-content: center">
      <div class="col-md-12">
        <!--PROFILE PICTURE AND COMPANY DETAILS CARD -->
        <div class="col-md-12">
          <b-card class="profile-card">
            <b-row align-v="center">
              <b-col
                class="icon-section"
                cols="auto"
              >
                <b-col>
                  <feather-icon
                    style="color: red"
                    icon="BookOpenIcon"
                    size="25"
                  />
                </b-col>
              </b-col>
              <b-col class="details border-left md-2">
                <b-img
                  :src="profileImage"
                  height="200"
                />
                <input
                  ref="fileInput"
                  type="file"
                  style="display: none"
                  accept="image/*"
                  @change="onFilePicked"
                >
                <div class="upload-button-container">
                  <b-button

                    class="btn-icon rounded-circle"
                    @click="onPickFile"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </div>
              </b-col>
              <b-col>
                <h4>{{ $t('company') }} / {{ $t('drivers_name') }}</h4>
                <h5>{{ profile.name }}</h5>

                <b class="add-new-address">
                  {{ $t('company_registration_number') }} / {{ $t('driver') }} NRIC
                </b>
                <br>
                <small><b>{{ profile.company_registration }}</b></small><br>
              </b-col>
              <b-col
                class="edit-section"
                cols="auto"
              >
                <a
                  v-b-modal.modal-3
                  style="color: red"
                >{{ $t('edit') }}</a>
              </b-col>
            </b-row>
          </b-card>
        </div>

        <!--USERNAME CARD-->
        <div class="col-md-12">
          <b-card class="profile-card">
            <b-row align-v="center">
              <b-col
                class="icon-section"
                cols="auto"
              >
                <b-col>
                  <feather-icon
                    style="color: red"
                    icon="MailIcon"
                    size="25"
                  />
                </b-col>
              </b-col>
              <b-col class="details border-left">
                <h4>{{ $t('user_name' ) }}</h4>
                <small>{{ profile.email }}</small>
              </b-col>
              <b-col
                class="edit-section"
                cols="auto"
              >
                <a
                  v-b-modal.modal-4
                  style="color: red"
                >{{ $t('edit') }}</a>
              </b-col>
            </b-row>
          </b-card>
        </div>

        <!--ADDRESS CARD -->
        <div class="col-md-12">
          <b-card class="profile-card">
            <b-row align-v="center">
              <b-col
                class="icon-section"
                cols="auto"
              >
                <b-col>
                  <feather-icon
                    style="color: red"
                    icon="MapPinIcon"
                    size="25"
                  />
                </b-col>
              </b-col>
              <b-col class="details border-left">
                <h4>{{ $t('address') }}</h4>
                <small>{{ profile.addresses ? profile.addresses[0].address1 : '' }}</small><br>
                <small>{{ profile.addresses ? profile.addresses[0].address2 : '' }}</small><br>
                <small>{{ profile.addresses ? profile.addresses[0].postcode : '' }}</small><br>
                <small>{{ profile.addresses ? profile.addresses[0].area : '' }}</small><br>
                <small>{{ profile.addresses ? profile.addresses[0].state : '' }}</small><br>
                <small>{{ profile.addresses ? profile.addresses[0].country : '' }}</small><br>
              </b-col>
              <b-col
                class="edit-section"
                cols="auto"
                @click="setupaddress(profile.addresses[0])"
              >
                <a
                  v-b-modal.modal-5
                  style="color: red"
                >{{ $t('edit') }}</a>
              </b-col>
            </b-row>
          </b-card>
        </div>

        <!--CONTACTS CARD -->
        <div class="col-md-12">
          <b-card class="profile-card">
            <b-row align-v="center">
              <b-col
                class="icon-section"
                cols="auto"
              >
                <b-col>
                  <feather-icon
                    style="color: red"
                    icon="SmartphoneIcon"
                    size="25"
                  />
                </b-col>
              </b-col>
              <b-col class="details border-left">
                <!-- <h4>Phone Number</h4> -->
                <h4>{{ $t('mobile_number') }}</h4>
                <small>{{ profile.phone }}</small><br>
              </b-col>
              <b-col
                class="edit-section"
                cols="auto"
              >
                <a
                  v-b-modal.modal-6
                  style="color: red"
                >{{ $t('edit') }}</a>
              </b-col>
            </b-row>
          </b-card>
        </div>

        <!--UPLOADED DOCUMENTS CARD -->
        <div class="col-md-12">
          <b-card class="profile-card">
            <b-row align-v="center">
              <b-col
                class="icon-section"
                cols="auto"
              >
                <b-col><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-monitor"
                  color="red"
                >
                  <rect
                    x="2"
                    y="3"
                    width="20"
                    height="14"
                    rx="2"
                    ry="2"
                  />
                  <line
                    x1="8"
                    y1="21"
                    x2="16"
                    y2="21"
                  />
                  <line
                    x1="12"
                    y1="17"
                    x2="12"
                    y2="21"
                  /></svg></b-col>
              </b-col>
              <b-col class="details border-left">
                <h4>{{ $t('uploaded_documents') }}</h4>
                <b-row>
                  <div class="col-md-12">
                    <div
                      class="col-md-12 text-center mb-4"
                      style="height: 700px !important;"
                    >
                      <div class="col-md-12 mb-1">
                        <label>SSM {{ $t('document') }}</label>
                      </div>

                      <iframe
                        v-if="profile.smart_ssm_document"
                        :src="$s3URL+'uploads/user/' + profile.smart_ssm_document"
                        width="100%"
                        height="100%"
                      />
                      <b-button
                        v-if="!profile.smart_ssm_document"
                        variant="danger"
                      >
                        {{ $t('not_uploaded') }}
                      </b-button>
                    </div>
                  </div>
                </b-row>
              </b-col>
              <b-col
                class="edit-section"
                cols="auto"
              >
                <a
                  v-b-modal.modal-7
                  style="color: red"
                >{{ $t('edit') }}</a>
              </b-col>
            </b-row>
          </b-card>
        </div>

        <!-- BANK DETAILS CARD -->
        <div class="col-md-12">
          <b-card class="profile-card">
            <b-row align-v="center">
              <b-col
                class="icon-section"
                cols="auto"
              >
                <b-col>
                  <feather-icon
                    style="color: red"
                    icon="TrelloIcon"
                    size="25"
                  />
                </b-col>
              </b-col>
              <b-col class="details border-left">
                <h4>{{ $t('bank_name') }}</h4>
                <h5 class="">
                  {{ !profile.bank_name ? $t('not_set') : profile.bank_name }}
                </h5>
                <h4>{{ $t('bank_account_number') }}</h4>
                <h5 class="">
                  {{ !profile.bank_account ? $t('not_set') : profile.bank_account }}
                </h5>
              </b-col>
              <b-col
                class="edit-section"
                cols="auto"
              >
                <a
                  v-b-modal.modal-99
                  style="color: red"
                >{{ $t('edit') }}</a>
              </b-col>
            </b-row>
          </b-card>
        </div>

        <!--BUTTON TO DASHBOARD -->
        <b-col cols="12 text-center mb-3">
          <b-button
            variant="danger"
            :to="{ name: 'dashboard' }"
            class="mr-1"
          >{{ $t('back_to_dashboard') }}</b-button>
        </b-col>
      </div>

      <div>
        <!-- EDIT COMPANY MODAL -->
        <b-modal
          id="modal-3"
          ref="universal-modal"
          title="Edit Company"
          hide-footer
          centered
        >
          <b-form-group
            label="Company Name"
            label-for="input-2"
          >
            <b-form-input v-model="form.name" />
          </b-form-group>
          <b-form-group
            label="Company Registration"
            label-for="input-2"
          >
            <b-form-input v-model="form.company_registration" />
          </b-form-group>
          <div class="text-right">
            <b-button
              variant="primary"
              class="mr-1"
              @click="updateprofile()"
            >Save</b-button>
          </div>
        </b-modal>

        <!-- EDIT USER NAME MODAL -->
        <b-modal
          id="modal-4"
          ref="universal-modal"
          title="Edit Username"
          hide-footer
          centered
        >
          <validation-observer ref="emailRules">
            <b-form-group
              label="Username"
              label-for="input-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="form.email"
                  type="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="text-right">
              <b-button
                variant="primary"
                class="mr-1"
                @click="updateEmailProfile()"
              >Save</b-button>
            </div>
          </validation-observer>
        </b-modal>

        <!-- EDIT BANK DETAILS MODAL -->
        <b-modal
          id="modal-99"
          ref="universal-modal"
          title="Edit Bank Details"
          hide-footer
          centered
          no-close-on-backdrop
        >
          <b-form-group label="Bank Name">
            <v-select
              v-model="selected"
              placeholder="Select a bank"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="bankList"
              class="select-size-lg"
            />
          </b-form-group>
          <b-form-group
            label="Account Number"
            label-for="basicInput"
          >
            <b-form-input
              id="basicInput"
              v-model="form.bank_account"
              placeholder="Enter account number"
            />
          </b-form-group>
          <div class="text-right">
            <b-button
              variant="primary"
              class="mr-1"
              @click="updateprofile()"
            >Save</b-button>
          </div>
        </b-modal>

        <!-- EDIT ADDRESS MODAL -->
        <b-modal
          id="modal-5"
          ref="universal-modal"
          title="Edit Address"
          hide-footer
          centered
        >
          <b-form-group
            label="Address Line 1"
            label-for="input-2"
          >
            <b-form-input v-model="addressdata.address1" />
          </b-form-group>
          <b-form-group
            label="Address Line 2"
            label-for="input-2"
          >
            <b-form-input v-model="addressdata.address2" />
          </b-form-group>
          <b-form-group
            label="Postcode"
            label-for="input-2"
          >
            <b-form-input v-model="addressdata.postcode" />
          </b-form-group>
          <b-form-group
            label="State"
            label-for="input-2"
          >
            <b-form-input v-model="addressdata.state" />
          </b-form-group>
          <b-form-group
            label="Area"
            label-for="input-2"
          >
            <b-form-input v-model="addressdata.area" />
          </b-form-group>
          <b-form-group
            label="Country"
            label-for="input-2"
          >
            <b-form-input v-model="addressdata.country" />
          </b-form-group>
          <div class="text-right">
            <b-button
              v-if="!isLoading"
              variant="primary"
              class="mr-1"
              @click="updateprofileaddress()"
            >Save</b-button>
            <b-button
              v-if="isLoading"
              variant="dark"
              class="mr-1"
            >Saving...</b-button>
          </div>
        </b-modal>

        <!-- EDIT PHONE NUMBER MODAL -->
        <b-modal
          id="modal-6"
          ref="universal-modal"
          title="Edit Phone Number"
          hide-footer
          centered
        >
          <b-form-group
            label="Phone"
            label-for="input-2"
          >
            <b-form-input
              v-model="form.phone"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            />
          </b-form-group>

          <div class="text-right">
            <b-button
              variant="primary"
              class="mr-1"
              @click="updateprofile()"
            >Save</b-button>
          </div>
        </b-modal>

        <!--Modal Edit Upload Documents-->
        <b-modal
          id="modal-7"
          ref="universal-modal"
          title="Edit Documents"
          hide-footer
          centered
        >
          <b-row>
            <div class="col-md-12 text-center">
              <div class="col-md-12 mb-1">
                <label>Company Document </label>
              </div>

              <b-col
                class="b-avatar badge-light-danger rounded mb-2"
                style="width: 100%; height: 154px"
              >
                <iframe
                  :src="img"
                  width="100%"
                  height="100%"
                />
              </b-col>
              <b-col class="text-center">
                <b-col
                  cols="12"
                  class="file btn btn-primary btn-sm mb-2"
                >
                  <FeatherIcon icon="Edit2Icon" /> SSM Documents
                  <b-form-file
                    v-model="profile.new_ssm"
                    placeholder="Profile Pic"
                    drop-placeholder="Drop file here..."
                    style="
                      position: absolute;
                      font-size: 50px;
                      opacity: 0;
                      right: 0;
                    "
                    accept=".pdf,.doc"
                    @change="onFileChange"
                  />
                </b-col>
              </b-col>
            </div>
          </b-row>
          <div class="text-right">
            <b-button
              v-if="!isLoading"
              variant="primary"
              class="mr-1"
              @click="saveNewSSMDocument"
            >Save</b-button>
            <b-button
              v-if="isLoading"
              variant="dark"
              class="mr-1"
            >Saving...</b-button>
          </div>
        </b-modal>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { getUserData } from '@/auth/utils'
import axios from '@axios'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      required,
      email,
      userdata: null,
      isLoading: false,
      user: { name: 'Name' },
      profile: {},
      fetching: true,
      form: {},
      addressdata: {},
      img: null,
      img_cdic: null,
      profileImage: null,
      selected: null,
      bankList: [
        { title: 'Maybank / Maybank Islamic' },
        { title: 'Affin Bank Berhad / Affin Islamic Bank' },
        { title: 'Alliance Bank Malaysia Berhad' },
        { title: 'Ambank Berhad' },
        { title: 'Bank Islam Malaysia' },
        { title: 'Bank Kerjasama Rakyat Malaysia Berhad' },
        { title: 'Bank Muamalat' },
        { title: 'Bank Pertanian Malaysia Berhad (Agrobank)' },
        { title: 'Bank Simpanan Nasional Berhad' },
        { title: 'CIMB Bank Berhad' },
        { title: 'Citibank Berhad' },
        { title: 'Hong Leong Bank' },
        { title: 'HSBC Bank Malaysia Berhad' },
        { title: 'Kuwait Finance House (Malaysia) Berhad' },
        { title: 'OCBC Bank (Malaysia) Berhad' },
        { title: 'Public Bank' },
        { title: 'RHB Bank' },
        { title: 'Standard Chartered Bank' },
        { title: 'United Overseas Bank Berhad' },
      ],
    }
  },
  watch: {
    'userdata.pic': function (val) {
      this.userdata.pic = val
    },
  },
  created() {
    this.userdata = getUserData()
    this.myprofile()
  },
  methods: {
    onFilePicked(event) {
      const { files } = event.target
      const reader = new FileReader()
      const formData = new FormData()
      formData.append('pic', files[0])
      reader.readAsDataURL(files[0])
      reader.onload = async e => {
        this.profileImage = e.target.result
        this.$http.post('/updateUserImage', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(response => {
          this.userdata.pic = response.data.custom_data.pic
          if (response.data.status) {
            localStorage.setItem('userData', JSON.stringify(this.userdata))
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Photo Successful!',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
            setTimeout(() => {
              location.reload()
            }, 1000)
          }
        })
      }
    },
    onPickFile() {
      this.$refs.fileInput.click()
    },
    showSSM(name) {
      window.open(
        `${this.$s3URL}uploads/user/${name}`,
        '_blank',
      )
    },
    onFileChange(e) {
      const file = e.target.files[0]
      this.img = URL.createObjectURL(file)
    },

    onFileChange2(e) {
      const file = e.target.files[0]
      this.img_cdic = URL.createObjectURL(file)
    },

    setupaddress(address) {
      this.addressdata = { ...address }
    },
    async saveNewSSMDocument() {
      this.isLoading = true
      const self = this
      const formData = new FormData()
      for (const key in self.profile) {
        formData.append(key, self.profile[key])
      }
      const response = await this.$http.post('/updateSSMDocument', formData)
      this.isLoading = false
      this.$refs['universal-modal'].hide()
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: '',
          icon: 'CoffeeIcon',
          variant: 'success',
          text: response.data.message,
        },
      })

      this.myprofile()
    },
    async updateprofileaddress() {
      this.isLoading = true
      const response = await this.$http.post(
        '/updateaddress',
        this.addressdata,
      )
      this.$refs['universal-modal'].hide()
      this.isLoading = false
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: '',
          icon: 'CoffeeIcon',
          variant: 'success',
          text: response.data.message,
        },
      })

      this.myprofile()
    },
    myprofile() {
      const self = this
      const headers = {
        Accept: 'application/json',
      }
      axios
        .get(`${this.$appURL}my_profile`, { headers })
        .catch(() => {})
        .then(response => {
          self.fetching = false
          self.profile = response.data.data[0]

          self.form = { ...self.profile }

          self.profile.cdic = (self.profile.cdic) ? self.profile.cdic : 'imagenotavailable.png'
          self.profileImage = (self.profile.pic) ? self.profile.pic : 'imagenotavailable.png'
          self.img_cdic = `${self.$s3URL}uploads/user/${self.profile.cdic}`
          self.img = `${self.$s3URL}uploads/user/${self.profile.smart_ssm_document}`
          self.profileImage = `${this.$s3URL}uploads/user/${self.profileImage}`
          self.selected = !self.profile.bank_name ? null : { title: self.profile.bank_name }
        })
    },
    updateEmailProfile() {
      const self = this
      this.$refs.emailRules.validate().then(success => {
        if (success) {
          const headers = {
            Accept: 'application/json',
          }
          axios
            .post(`${this.$appURL}updateprofile`, self.form, { headers })
            .catch(() => {})
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: '',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.$refs['universal-modal'].hide()
              self.myprofile()
            })
        }
      })
    },
    updateprofile() {
      const self = this
      if (self.selected) { self.form.bank_name = self.selected.title }
      const headers = {
        Accept: 'application/json',
      }
      axios
        .post(`${this.$appURL}updateprofile`, self.form, { headers })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        })
        .then(response => {
        if(response.status == 422){
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.message,
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: Object.values(response.data.errors).join(","),
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.message,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.$refs['universal-modal'].hide()
          self.myprofile()
          }
        })
        
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/myprofile.scss";
</style>
